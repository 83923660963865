let Dollar = new Intl.NumberFormat('en-NZ', { 
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});

let Percent = new Intl.NumberFormat('en-NZ', {
  style: "percent",
  maximumFractionDigits: 2,
  trailingZeroDisplay: "stripIfInteger"
})

export default () => ({

  glBalance: null,
  recBalance: null,

  init() {
    this.glBalance = parseFloat(this.$el.dataset.glBalance).toFixed(2)
    this.recBalance = parseFloat(this.$el.dataset.recBalance).toFixed(2)
  },

  get goodVariance() {
    return Math.abs(this.variance) <= 1;
  },

  get badVariance() {
    return Math.abs(this.variance) > 1;
  },

  get variance() {
    if (this.glBalance === null || this.recBalance === null || this.glBalance == "" || this.recBalance == "") return
  
    return this._parseNumber(this.recBalance) - this._parseNumber(this.glBalance);
  },

  get formattedVarianceAmount() {
    return Dollar.format(this.variance)
  },

  get variancePercent() {
    if (this.glBalance === null || this.recBalance === null || this.glBalance == "" || this.recBalance == "") return

    return (Math.abs(this.variance) / this._parseNumber(this.glBalance));
  },

  get formattedVariancePercent() {
    return Percent.format(this.variancePercent)
  },

  _parseNumber(n) {
    return parseFloat(n.replace(/[^0-9.-]/, ""))
  }

})