export default () => ({
  init() {
    this.observer = new MutationObserver(this.update.bind(this));
    this.observer.observe(this.$el, {
      childList: true,
      attributes: false,
      subtree: true
    });
    this.update();
  },

  destroy() {
    if (this.observer) this.observer.disconnect()
  },

  update() {
    const numItems = this.$refs.itemContainer.children.length
    
    if (numItems > 0) { 
      this.$refs.emptyMessage.classList.add("hidden")
      this.$el.querySelectorAll("[data-hide-on-empty]").forEach(e => e.classList.remove("hidden"))
    } else {
      this.$refs.emptyMessage.classList.remove("hidden")
      this.$el.querySelectorAll("[data-hide-on-empty]").forEach(e => e.classList.add("hidden"))
    }
  }
})