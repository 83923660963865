// ~1 second and we mark this sucker read
const hoverTimeToMarkRead = 1000;

export default () => ({

  read: false,
  isBusy: false,

  init() {
    this.read = this.$el.dataset.read === "true"
  },

  mouseenter() {
    this.hoverTimeout = setTimeout(() => this.markRead(), hoverTimeToMarkRead)
  },

  mouseleave() {
    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout)
      this.hoverTimeout = null
    }
  },

  async markRead() {
    if (this.read) return

    this.read = true
    
    // now update server
    const formData = new FormData()
    formData.append("notification[read]", true)

    if (document.querySelector("meta[name='csrf-param']")) {
      formData.append(document.querySelector("meta[name='csrf-param']").content, document.querySelector("meta[name='csrf-token']").content)
    }
    this.isBusy = true

    await fetch(this.$el.dataset.updateUrl, {
      body: formData,
      method: "PATCH"
    }) 

    this.isBusy = false
  }

})