import Sortable from "sortablejs";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default () => ({
  completedSortable: null,
  incompleteSortable: null,
  id: null,

  init() {
    const toggleTask = (event) => {
      this.$nextTick(() => {
        event.item.querySelector("input[type='checkbox']").click();
      });
    };

    this.id = this.$el.id;

    this.incompleteSortable = Sortable.create(this.$refs.incomplete, {
      animation: 150,
      group: this.$el.id,
      onEnd: (event) => this.saveSortPosition(event),
      onAdd: toggleTask,
      forceFallback: params.draggable_fallback,
    });

    this.completedSortable = Sortable.create(this.$refs.completed, {
      animation: 150,
      group: this.$el.id,
      onEnd: (event) => this.saveSortPosition(event),
      onAdd: toggleTask,
      forceFallback: params.draggable_fallback,
    });
  },

  destroy() {
    if (this.observer) this.observer.disconnect();
  },

  moveToggledTaskToCorrectList(event) {
    const el = event.target;
    const completed = event.detail.completed;

    const destinationEl = completed ? this.completedSortable.el : this.incompleteSortable.el;

    // already in the correct list - do nothing (happens on a drag)
    if (el.parentElement.id === destinationEl.id) return;

    const translateStartY = el.getBoundingClientRect().top;
    destinationEl.insertBefore(el, destinationEl.firstChild);
    const translateEndY = el.getBoundingClientRect().top;

    el.style.transitionTimingFunction = null;
    el.style.transitionProperty = null;
    el.style.transitionDuration = null;
    el.style.transform = `translateY(${translateStartY - translateEndY}px)`;
    el.style.zIndex = 99;

    requestAnimationFrame(() => {
      el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 0.2, 1)";
      el.style.transitionProperty = "all";
      el.style.transitionDuration = "250ms";
      el.style.transform = "translateY(0)";
      setTimeout(() => {
        el.style.zIndex = null;
      }, 260);
    });

    event.preventDefault();
    return false;
  },

  saveSortPosition(event) {
    const el = event.item;
    const field = el.querySelector("input[name='scratch_task[sort_order_position]']");
    field.setAttribute("value", event.newIndex);
    field.dispatchEvent(new Event("input", { bubbles: true }));
  },
});
