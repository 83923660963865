export default () => ({

  selectedLocation: null,

  init() {
    this.optionsData = JSON.parse(this.$el.dataset.automaticDetectionOptions)
  
    if (!this.optionsData || this.optionsData.length == 0) return

    let initialSelection = this.$el.querySelector("select[x-model='selectedLocation']").value
    this.selectedLocation =  initialSelection || JSON.stringify(this.optionsData[0].location)

    this.$watch('selectedLocation', (value) => {
      if (value != "manual") {
        this.recBalance = String(this.optionsData.find((x) => JSON.stringify(x.location) == value).value_cents / 100)
        let el = this.$el.querySelector("input[name*='rec_balance']")
        el.dispatchEvent(new Event('change'));
      }
    })
  },



});