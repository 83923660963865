import { createPicker } from "picmo";

const defaultEmoji =
  "📂 📙 🗼 🚩 ⬇️ ☂ 👵 🏥 ⭐️ 🔼 🎱 🍄 🐊 🔅 🖨 ⚫️ 🚺 🚮 🌝 ❤️ ⛔️ 🍕 🌔 🌁 🔽 😘 🕋 💅 ⭕️ 🍒 🔟 ✋ 🎿 📶 🐨 🗒 ➖ 📻 👄 🚲 ⚙ 🚏 💕 😻 💞 🍟 1️⃣ ✌️ 🆑 🛩 🌄 📦 🌓 📪 🗨 👉 💈 🏌 📳 💟 🔟 🕰 🏍 6️⃣ 🛫 🐲 ❄️ ⌚️ ⬇️ 🚇 📒 ☕️ 😿 😪 🕢 👝 🍪 🔁 🍡 🎳 🍎 🎇 🏤 🚀 🎞 ♒️ ®️ 👠 🏹 ➕ 😆 🕚 🛅 ⚛ 👤 🐨 😣 😾 🌝".split(
    " "
  );

// if we put the picker inside the function, then Alpine makes it reactive
// and the proxy doesn't deal with with .destroy() calls (random errors about private functions)
let picker = null;

export default () => ({
  init() {
    picker = createPicker({
      rootElement: this.$el,
      showPreview: false,
      showVariants: false,
      autoFocus: "search",
    });

    picker.addEventListener("emoji:select", (selection) => {
      this.$dispatch("selected", selection);
    });

    let initialEmoji = this.selectedEmoji;

    if (!initialEmoji || initialEmoji.length == 0) {
      initialEmoji = defaultEmoji[Math.floor(Math.random() * defaultEmoji.length)];
    }

    this.$nextTick(() => this.$dispatch("selected", { emoji: initialEmoji }));
  },

  destroy() {
    if (picker && picker.destroy) {
      try {
        picker.destroy();
      } catch {
        console.log("picker destroy errored");
      }
    }
  },
});
