document.addEventListener("turbo:load", () => {
  loadReadOnlyForms(document);
})

document.addEventListener("turbo:frame-load", (e) => {
  loadReadOnlyForms(e.target)
});

function loadReadOnlyForms(container) {
  container.querySelectorAll("form[data-switch-to-read-only='true']").forEach((form) => {

    if (form.closest("*[data-read-only='true']") !== null) {
      form.querySelectorAll("input, select, textarea, button").forEach((input) => {
        input.setAttribute("readonly", true);
        input.setAttribute("disabled", true);
        input.setAttribute("tabindex", -1);
        //input.classList.add("pointer-events-none")
        input.classList.add("hover:cursor-not-allowed")
      });
    }

  });
}