export default () => ({


  init() {
    this.container = this.$el
  },

  refresh(event) {
    // note - have to re-find these here as if we re-load the turbo frame it's a new selector
    const countrySelector = this.container.querySelector("select[id$=holidays_country]")
    const regionSelector = this.container.querySelector("select[id$=holidays_region]")

    const selectedCountry = countrySelector.value
    let selectedRegion = regionSelector.value

    if (event.target.id == "schedule_holidays_country") {
      selectedRegion = selectedCountry
    }

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    params.set("holidays_country", selectedCountry)
    params.set("holidays_region", selectedRegion)

    const newURL = `${url.origin}${url.pathname}?${params}`
    this.$refs.regionFrame.src = newURL
    this.$refs.nextHolidaysFrame.src = newURL
  }

});