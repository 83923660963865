const apiEndpoint = `https://api.wikimedia.org/feed/v1/wikipedia/en/onthisday/events/#{mm}/#{dd}`;

const badKeywords = [
  "kill",
  "die",
  "disaster",
  "fight",
  "riot",
  "islamic state",
  "murder",
  "assassinat",
  "attack",
  "arrest",
  "slave",
  "death",
  "dead",
  "explode",
  "accident",
  "crash",
  "lost",
  "disappeared",
  "disappearance",
  "bombing",
  "bomb",
  "war",
  "militant",
  "suicide",
  "expel",
  "battle",
  "massacre",
  "coup",
];

export default () => ({
  hasFact: false,
  fact: "",
  factYear: "",
  factUrl: "/",

  async init() {
    // request the wikipedia api
    let replacedApiEndpoint = apiEndpoint.replace("#{mm}", new Date().getMonth() + 1).replace("#{dd}", new Date().getDate());
    result = await fetch(replacedApiEndpoint);
    let data = await result.json();

    // filter out bad facts
    let goodFacts = data.events.filter((event) => {
      return !badKeywords.some((k) => event.text.toLowerCase().indexOf(k) != -1);
    });

    let randomFact = goodFacts[Math.floor(Math.random() * goodFacts.length)];
    this.fact = randomFact.text;
    this.factYear = randomFact.year;
    this.factUrl = randomFact.pages[0].content_urls.desktop.page;
    this.hasFact = true;
  },
});
