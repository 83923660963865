export default () => ({

  isAdding: false,

  init() {
    this.$watch('isAdding', () => {
      if (this.isAdding) {
        const selectEl = this.$el.querySelector("select")
        selectEl.disabled = false

        // focus the new field
        this.$nextTick(() => this.$el.querySelector("input").focus())
      } else {        
        // make sure "new" isn't selected
        const selectEl = this.$el.querySelector("select")
        selectEl.disabled = false

        if (selectEl.value === "new") { 
          let opt = selectEl.querySelector("option:not([value=''])") || selectEl.querySelector("option:first-child")
          opt.setAttribute("selected", "selected")
          selectEl.value = opt.value
        }
      }
    })
  },

  select: {
    ['@change']() {
      if (this.$event.target.value === "new") {
        this.isAdding = true
      } 
    }
  },

  selectWrapper: {
    ['x-show']() {
      return !this.isAdding
    },
  },

  newFields: {
    ['x-show']() {
      return this.isAdding
    },
  }


})