import Sortable from 'sortablejs';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default () => ({

  init() {
    const alpineComponent = this
    this.sortable = Sortable.create(this.$refs.table, {
      forceFallback: params.draggable_fallback,
      handle: ".drag-handle",
      onStart: function () {
        this.el.classList.remove('use-hover');
      },
      onEnd: function () {
        this.el.classList.add('use-hover');
        alpineComponent.onEnd(...arguments)
      }
    });
  },

  destroy() {
    if (this.sortable) this.sortable.destroy()
  },

  onEnd({ item, newIndex }) {
    let objectId = item.dataset.objectId

    const form = this.$refs.form
    let originalAction = form.action
    form.action = form.action.replace("OBJECT_ID", objectId)
    form.querySelector("input[name*=sort_order_position]").value = newIndex
    form.requestSubmit()
    form.action = originalAction
  }

})