import * as PusherPushNotifications from "@pusher/push-notifications-web";

let beamsClient;

if ("PushManager" in window) {
  beamsClient = new PusherPushNotifications.Client({
    instanceId: "ac4ba710-1e5b-4172-a4fb-4ad5bf74a435",
  });
}

export default () => ({
  beamsRegistrationState: null,
  userId: null,
  hideDesktopNotificationPrompt: false,
  enablingPush: false,
  updateUserUrl: null,
  unreadCount: 0,

  async init() {
    this.userId = this.$el.dataset.userId;
    this.hideDesktopNotificationPrompt = JSON.parse(this.$el.dataset.hideDesktopNotificationPrompt);
    this.updateUserUrl = this.$el.dataset.updateUserUrl;
    this.notificationSoundUrl = this.$el.dataset.notificationSoundUrl;

    this.audio = new Audio(this.notificationSoundUrl);

    this.listenForUnreadCount();

    if (beamsClient) {
      this.beamsRegistrationState = await beamsClient.getRegistrationState();

      let beamsId = await beamsClient.getUserId();

      // Check if the Beams user matches the user that is currently logged in
      if (beamsId && beamsId !== this.userId) {
        console.log("Beams user ID does not match current user ID");
        // Unregister for notifications
        await beamsClient.stop();
      }

      if (this.beamsRegistrationState === PusherPushNotifications.RegistrationState.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS || this.beamsRegistrationState === PusherPushNotifications.RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS) {
        // set our user id and everything
        this.enablePush();
      }
    }
  },

  get showPermissionPrompt() {
    return (
      beamsClient &&
      !this.hideDesktopNotificationPrompt &&
      (this.beamsRegistrationState === PusherPushNotifications.RegistrationState.PERMISSION_PROMPT_REQUIRED || this.beamsRegistrationState === PusherPushNotifications.RegistrationState.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS)
    );
  },

  async enablePush() {
    this.enablingPush = true;
    await beamsClient.start();
    // const deviceID = await beamsClient.getDeviceId();
    // console.log(`Successfully registered with Beams. Device ID: ${deviceID}`);

    const csrfToken = {};
    csrfToken[document.querySelector('meta[name="csrf-param"]').content] = document.querySelector("meta[name='csrf-token']").content;

    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: "/beams_tokens/",
      headers: csrfToken,
    });
    await beamsClient.setUserId(this.userId, beamsTokenProvider);

    this.beamsRegistrationState = await beamsClient.getRegistrationState();
    console.log({ beamsRegistrationState: this.beamsRegistrationState });
    this.enablingPush = false;
  },

  async deregisterPush() {
    beamsClient.stop();
  },

  hidePrompt() {
    this.hideDesktopNotificationPrompt = true;

    const formData = new FormData();
    formData.append("user[hide_desktop_notification_prompt]", true);

    if (document.querySelector("meta[name='csrf-param']")) {
      formData.append(document.querySelector("meta[name='csrf-param']").content, document.querySelector("meta[name='csrf-token']").content);
    }

    fetch(this.updateUserUrl, {
      body: formData,
      method: "PATCH",
    });
  },

  playNewNotificationSound() {
    // try {
    //   this.audio.addEventListener('canplaythrough', (event) => {
    //     // the audio is now playable; play it if permissions allow
    //     this.audio.play();
    //   });
    // } catch (e) {
    //   console.warn("Audio Playback Failed", e)
    // }
  },

  listenForUnreadCount() {
    const observer = new MutationObserver(() => {
      let existingCount = this.unreadCount;
      this.updateUnreadCount();

      if (this.unreadCount > existingCount) {
        this.playNewNotificationSound();
        const button = this.$el.querySelector("button.bell-button");
        button.classList.add("animate-bounce");
        setTimeout(() => button.classList.remove("animate-bounce"), 5000);
      }
    });

    observer.observe(this.$el.querySelector("#notification_list"), { attributes: true, childList: true, subtree: true });
    this.updateUnreadCount();
  },

  updateUnreadCount() {
    this.unreadCount = this.$el.querySelectorAll("li.unread").length;
  },
});
