export default () => ({
  ssoProviderDomains: [],
  ssoProvider: null,

  authMethod: "password",

  email: "",
  emailDomain: "",

  init() {
    this.authMethod = "password";
    this.ssoProviderDomains = JSON.parse(this.$el.dataset.ssoProviderDomains);
    this.ssoProviderEmails = JSON.parse(this.$el.dataset.ssoProviderEmails);

    this.email = this.$el.dataset.initialEmail;

    this.$watch("email", async (value) => {
      this.emailDomain = value.split("@")[1];

      const hashedEmail = await this.sha256(value);
      const emailAddressProvider = Object.keys(this.ssoProviderEmails).find((p) => this.ssoProviderEmails[p].includes(hashedEmail));

      const hashedDomain = await this.sha256(this.emailDomain);
      const emailDomainProvider = Object.keys(this.ssoProviderDomains).find((p) => this.ssoProviderDomains[p].includes(hashedDomain));

      const provider = emailAddressProvider || emailDomainProvider;

      if (provider) {
        this.authMethod = "sso";
        this.ssoProvider = provider;
      } else {
        this.authMethod = "password"; // back to default
      }
    });
  },

  async sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    return hashHex;
  },
});
