import { DirectUpload } from "@rails/activestorage";
import Alpine from "alpinejs";

class UploadProgress {
  constructor(attachment) {
    this.attachment = attachment;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    this.attachment.progress = (event.loaded / event.total) * 100;
  }
}

export default () => ({
  attachments: [],

  dragging: false,

  init() {
    this.removeAttachmentUrl = JSON.parse(this.$el.dataset.removeAttachmentUrl);
    this.attachmentFieldName = this.$el.dataset.attachmentFieldName;

    const model = JSON.parse(this.$el.dataset.model);

    if (model.attachment_json) {
      Object.entries(model.attachment_json).forEach((entry) => {
        const [signedId, attrs] = entry;

        let attachment = Alpine.reactive({
          id: signedId,
          filename: attrs.filename,
          preview_url: attrs.preview_url,
          previewable: attrs.previewable,
          full_url: attrs.full_url,
          virus_detected: attrs.virus_detected,
        });
        this.attachments.push(attachment);
      });
    }
  },

  loadFailed(attachment) {
    if (attachment.preview_url && attachment.preview_url != "") {
      attachment.previewFailed = true;
    }
  },

  dropFiles(e) {
    if (e.dataTransfer) {
      if (e.dataTransfer.files.length) {
        this.uploadFiles(Array.from(e.dataTransfer.files));
      }
    }
    this.dragging = false;
  },

  selectFiles(e) {
    this.uploadFiles(Array.from(e.target.files));
  },

  uploadFiles(addedFiles) {
    const url = this.$refs.fileField.dataset.directUploadUrl;

    addedFiles.forEach((f) => {
      const attachment = Alpine.reactive({
        id: Date.now() + Math.ceil(Math.random() * 100_000),
        uploading: true,
        filename: f.name,
        progress: 0,
      });
      this.attachments.push(attachment);

      const upload = new DirectUpload(f, url, new UploadProgress(attachment));
      upload.create((error, blob) => {
        if (error) {
          attachment.error = true;
        } else {
          this.saveBlob({ blob, attachment });
        }
      });
    });
  },

  async saveBlob({ blob, attachment }) {
    // now we have an uploaded blob, we need to save it against
    // the parent attaching record
    const form = this.$el.closest("form");

    const formData = new FormData();
    formData.append(this.attachmentFieldName, blob.signed_id);

    if (document.querySelector("meta[name='csrf-param']")) {
      formData.append(document.querySelector("meta[name='csrf-param']").content, document.querySelector("meta[name='csrf-token']").content);
    }

    const method = (form.querySelector("input[name='_method']")?.value || form.method).toUpperCase();

    const response = await fetch(form.action, {
      body: formData,
      method,
    });
    const data = await response.json();

    if (data.attachment_json) {
      // find our attachment in the response
      const createdAttachment = data.attachment_json[blob.signed_id];
      // need to do this property by property since otherwise the alpine Object proxy doesn't work
      // so changes aren't reactive
      attachment.id = blob.signed_id;
      attachment.preview_url = createdAttachment.preview_url;
      attachment.full_url = createdAttachment.full_url;
      attachment.uploading = false;
    }
  },

  async removeAttachment(attachment) {
    if (!confirm(`Are you sure you want to remove “${attachment.filename}”?`)) return;

    this.attachments = this.attachments.filter((a) => a.id !== attachment.id);

    const url = new URL(`${window.location.origin}/${this.removeAttachmentUrl}`);
    url.searchParams.append("signed_id", attachment.id);

    if (document.querySelector("meta[name='csrf-param']")) {
      url.searchParams.append(document.querySelector("meta[name='csrf-param']").content, document.querySelector("meta[name='csrf-token']").content);
    }

    fetch(url.href, {
      method: "DELETE",
    });
  },
});
