export default () => ({

  init() {
    const mentioned = JSON.parse(this.$el.dataset.mentioned)
    const content = this.$refs.content

    if (!content) return

    mentioned.forEach(m => {
      content.innerHTML = content.innerHTML.replaceAll(`@${m}`, `<cite class="text-gray-900 font-medium not-italic">@${m}</cite>`)
    })
  },

});