const greetings = {
  morning: {
    en: "Good morning",
    es: "Buenos días",
    mi: "Mōrena",
    de: "Guten Morgen",
    af: "Goeie more",
    zu: "Sawubona ekuseni",
    xh: "Molo kusasa",
    st: "Lumela hoseng",
    ga: "Maidin mhaith",
    gd: "Madainn mhath",
    fr: "Bonjour",
    zh: "Zǎoshang hǎo",
    hi: "Suprabhāt",
    ru: "Dobroye utro",
    ar: "Sabah alkhayr",
  },
  afternoon: {
    en: "Good afternoon",
    es: "Buenas tardes",
    mi: "Ahi ahi marie",
    de: "Guten Tag",
    af: "Goeie middag",
    zu: "Sawubona ntambama",
    xh: "Molo emini",
    st: "Lumela mantsiboea",
    ga: "Tráthnóna maith",
    gd: "Feasgar math",
    fr: "Bon après-midi",
    zh: "Xiàwǔ hǎo",
    hi: "Namaste",
    ru: "Dobryy den'",
    ar: "Masā' alkhayr",
  },

  evening: {
    en: "Good evening",
    es: "Buenas noches",
    mi: "Pō mārie",
    de: "Guten Abend",
    af: "Goeie naand",
    zu: "Sawubona kusihlwa",
    xh: "Molo ngokuhlwa",
    st: "Lumela bosiu",
    ga: "Oíche mhaith",
    gd: "Oidhche mhath",
    fr: "Bonsoir",
    zh: "Wǎnshàng hǎo",
    hi: "Shubh sandhyā",
    ru: "Dobryy vecher",
    ar: "Masā' alkhayr",
  },
};
const languageInfo = {
  en: { label: "English", url: "https://en.wikipedia.org/wiki/English_language" },
  es: { label: "Spanish", url: "https://en.wikipedia.org/wiki/Spanish_language" },
  mi: { label: "Māori", url: "https://en.wikipedia.org/wiki/Māori_language" },
  de: { label: "German", url: "https://en.wikipedia.org/wiki/German_language" },
  af: { label: "Afrikaans", url: "https://en.wikipedia.org/wiki/Afrikaans_language" },
  zu: { label: "Zulu", url: "https://en.wikipedia.org/wiki/Zulu_language" },
  xh: { label: "Xhosa", url: "https://en.wikipedia.org/wiki/Xhosa_language" },
  st: { label: "Sesotho", url: "https://en.wikipedia.org/wiki/Sesotho_language" },
  ga: { label: "Irish", url: "https://en.wikipedia.org/wiki/Irish_language" },
  gd: { label: "Scottish Gaelic", url: "https://en.wikipedia.org/wiki/Scottish_Gaelic" },
  fr: { label: "French", url: "https://en.wikipedia.org/wiki/French_language" },
  zh: { label: "Chinese", url: "https://en.wikipedia.org/wiki/Chinese_language" },
  hi: { label: "Hindi", url: "https://en.wikipedia.org/wiki/Hindi" },
  ru: { label: "Russian", url: "https://en.wikipedia.org/wiki/Russian_language" },
  ar: { label: "Arabic", url: "https://en.wikipedia.org/wiki/Arabic" },
};
export default () => ({
  greeting: "Hello",
  languageDescription: false,

  init() {
    setInterval(() => this.chooseGreeting(), 1000 * 60 * 15);
    this.chooseGreeting();
  },

  chooseGreeting() {
    timeOfDay = "morning";

    if (new Date().getHours() < 12) {
      timeOfDay = "morning";
    } else if (new Date().getHours() < 18) {
      timeOfDay = "afternoon";
    } else {
      timeOfDay = "evening";
    }

    let languages = Object.keys(greetings[timeOfDay]);
    let randomLanguage = languages[Math.floor(Math.random() * languages.length)];
    let randomGreeting = greetings[timeOfDay][randomLanguage];

    this.greeting = randomGreeting;

    if (randomLanguage != "en") {
      this.languageDescription = `That's "${greetings[timeOfDay]["en"]}" in ${languageInfo[randomLanguage].label}!`;
      this.languageLink = languageInfo[randomLanguage].url;
    }
  },
});
