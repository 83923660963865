export default () => ({
  selectedId: null,
  selected: {},

  init() {
    this.selectedId = this.$el.dataset.selectedId;

    const users = window.userData || JSON.parse(document.querySelector("#user_selector_data").innerText);
    this.selected = users.find((u) => u.id == this.selectedId);

    if (!this.$el.dataset.skipAutoSubmit) {
      this.$watch("selected", () => this.$el.closest("form").requestSubmit());
    }
  },

  sendOpen() {
    this.$dispatch("open-user-select", {
      triggeringEl: this.$el,
      valueTarget: document.querySelector(`#${this.$el.dataset.fieldId}`),
      onlyUsers: this.$el.dataset.onlyUserIds,
    });
  },

  events: {
    "@shared-listbox-value-selected.window"(e) {
      const { val, targetId } = e.detail;

      if (targetId == this.$el.dataset.fieldId) {
        this.selected = val;
      }

      e.preventDefault();
      return false;
    },
    "@click"() {
      this.sendOpen();
    },
    "@keydown"(e) {
      if (["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"].includes(e.key)) {
        e.stopPropagation();
        e.preventDefault();

        this.sendOpen();
      }
    },
    "@keydown.space.stop.prevent"() {
      this.sendOpen();
    },
    "@keydown.enter.stop.prevent"() {
      this.sendOpen();
    },
  },
});
