import Tribute from "tributejs";

export default () => ({

  init() {
    const options = JSON.parse(this.$el.dataset.possibleMentions)

    this.tribute = new Tribute({
      values: options,
      lookup: "username",
      fillAttr: "username",
      allowSpaces: false,
      menuItemTemplate: function (item) {
        return '<img src="'+ item.original.avatar_url + '" class="rounded-full w-6 h-6" ><span>' + item.string + '</span>'
      },
      noMatchTemplate: null
    });

    this.tribute.attach(this.$refs.field)
  },

});