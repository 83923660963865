export default () => ({
  day: 1,

  type: "working_day",

  maxWorkingDay: 30,
  minWorkingDay: 30,

  maxCalendarDay: 31,

  init() {
    // Set initial type from dataset if available
    const initialDueType = this.$el.dataset.initialDueType;
    if (initialDueType) {
      this.type = initialDueType;
    }

    // Set initial day value from dropdown
    const daySelect = this.$el.querySelector('[name*="[due_day]"]');
    if (daySelect && daySelect.value) {
      this.day = daySelect.value;
    }

    this.$watch("type", () => this.setOptions());
    this.$nextTick(() => this.setOptions());
  },

  setOptions() {
    let select = this.$refs.daySelect;
    let existingValue = parseInt(this.day, 10);

    select.querySelectorAll("option").forEach((e) => e.remove());

    if (this.type == "working_day") {
      if (existingValue < -1 * this.minWorkingDay || existingValue > this.maxWorkingDay) existingValue = 1;

      let opts = Array.from({ length: this.maxWorkingDay + 1 + this.minWorkingDay }, (x, i) => i).map((n) => n - this.minWorkingDay);
      opts.splice(this.minWorkingDay, 1);

      opts.forEach((n) => {
        const el = document.createElement("option", { value: n });
        el.textContent = n;
        if (n == existingValue) el.setAttribute("selected", "selected");

        select.appendChild(el);
      });
    } else if (this.type == "calendar_day") {
      if (existingValue < 1 || existingValue > this.maxCalendarDay) existingValue = 1;

      let opts = Array.from({ length: this.maxCalendarDay }, (x, i) => i + 1);
      opts.forEach((n) => {
        const el = document.createElement("option", { value: n });
        el.textContent = this._getNumberWithOrdinal(n);
        if (n == existingValue) el.setAttribute("selected", "selected");

        select.appendChild(el);
      });
    } else {
      const el = document.createElement("option", { value: null, disabled: true });
      el.textContent = "   --";

      select.appendChild(el);
    }
  },

  _getNumberWithOrdinal(n) {
    let s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  },
});
