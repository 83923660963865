import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { Turbo } from "@hotwired/turbo-rails";
import { externalTooltipHandler } from "./external-tooltip-handler";

document.addEventListener("turbo:load", () => {
  document.querySelectorAll("[data-chart=progress]").forEach((e) => makeProgressChart(e));
  document.querySelectorAll("[data-chart=progressByDay]").forEach((e) => makeProgressByDayChart(e));
  document.querySelectorAll("[data-chart=thisWeekSummary]").forEach((e) => makeThisWeekSummaryChart(e));
});

document.documentElement.addEventListener("turbo:frame-load", () => {
  document.querySelectorAll("[data-chart=progress]").forEach((e) => makeProgressChart(e));
});

function makeThisWeekSummaryChart(e) {
  if (e.dataset.chartRendered) return;
  e.dataset.chartRendered = true;

  const data = JSON.parse(e.dataset.info);
  const statusColors = JSON.parse(e.dataset.statusColors);
  const periodSlugs = JSON.parse(e.dataset.periodSlugs);

  const chart = new Chart(e, {
    type: "bar",
    data: {
      datasets: Object.entries(data).map(([label, set]) => {
        let d = Object.entries(set).map(([day, value]) => ({ x: day, y: value }));

        return {
          label: humanize(label),
          data: d,
          backgroundColor: statusColors[label],
          stack: label == "reviewed" ? "Stack 1" : "Stack 0",
          base: 0,
        };
      }),
    },
    options: {
      datasets: {
        bar: {
          minBarLength: 8,
          barPercentage: 0.8,
          borderRadius: {
            topLeft: 4,
            topRight: 4,
            bottomLeft: 4,
            bottomRight: 4,
          },
        },
      },
      onClick: (evt) => {
        const points = chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true);

        if (points.length) {
          const firstPoint = points[0];
          const value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index].x;

          if (periodSlugs[value]) {
            Turbo.visit(`/periods/${periodSlugs[value]}`);
          }
        }
      },
      onHover: (evt) => {
        const points = chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true);

        if (points.length) {
          e.style.cursor = "pointer";
        } else {
          e.style.cursor = "default";
        }
      },
      responsive: true,
      scales: {
        x: {
          type: "time",
          stacked: true,
          time: {
            unit: "day",
            displayFormats: {
              day: "EEE, MMM do",
            },
          },
          min: e.dataset.startOfWeek,
          max: e.dataset.endOfWeek,
          grid: {
            display: false,
            tickLength: 0,
          },
          ticks: {
            padding: 20,
          },
        },
        y: {
          stacked: true,
          beginAtZero: true,
          grid: {
            display: false,
            tickLength: 0,
          },
          ticks: {
            padding: 20,
            stepSize: 1,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          padding: 10,
          boxPadding: 10,
          usePointStyle: true,
          caretSize: 0,
          callbacks: {
            label(tooltipItem) {
              let s = tooltipItem.raw.y > 1 || tooltipItem.raw.y == 0 ? "s" : "";
              return `${tooltipItem.formattedValue} task${s}`;
            },
            title(tooltipItem) {
              const item = tooltipItem[0];
              return format(item.parsed.x, "EEEE, MMM do");
            },
          },
        },
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 5,
            padding: 25,
            boxHeight: 5,
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
      },
    },
  });
}

function makeProgressChart(e) {
  if (e.dataset.chartRendered) return;
  e.dataset.chartRendered = true;

  const data = JSON.parse(e.dataset.info);

  const backgroundColor = Object.keys(data[0]).includes("Attention needed") ? ["#22C55E", "#F97316", "#EF4444", "#F5F5F5"] : ["#22C55E", "#F5F5F5"];

  new Chart(e, {
    type: "doughnut",
    data: {
      datasets: [
        {
          labels: Object.keys(data[0]),
          data: Object.values(data[0]),
          //        backgroundColor: [e.dataset.statusColor, "#F5F5F5"],
          backgroundColor,
          weight: 1.5,
          hoverBorderWidth: 0,
        },
        {
          weight: 0.6,
        },
        data[1]
          ? {
              labels: Object.keys(data[1]),
              data: Object.values(data[1]),
              backgroundColor: ["#A855F7", "#F5F5F5"],
              hoverBorderWidth: 0,
            }
          : {},
      ],
    },
    options: {
      borderRadius: 25,
      rotation: 180,
      cutout: "65%",
      animation: { animateRotate: true },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: -10,
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: "nearest",
          external: externalTooltipHandler,
        },
      },
    },
  });
}

function makeProgressByDayChart(e) {
  const data = JSON.parse(e.dataset.info);

  new Chart(e, {
    type: "line",
    data: {
      datasets: [
        {
          label: "Actual",
          data: data.actual,
          borderColor: "#22C55E",
          backgroundColor: "#22C55E",
          borderWidth: 5,
          pointRadius: 0,
          borderJoinStyle: "round",
          borderCapStyle: "round",
          cubicInterpolationMode: "monotone",
          clip: false,
        },
        {
          label: "Expected",
          data: data.expected,
          borderColor: "#D4D4D8",
          backgroundColor: "#D4D4D8",
          borderWidth: 5,
          borderDash: [5, 10],
          pointRadius: 0,
          borderCapStyle: "round",
          cubicInterpolationMode: "monotone",
          clip: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            display: false,
            tickLength: 0,
          },
          border: {
            display: false,
          },
          //          min: 0,
          max: 100,
          ticks: {
            padding: 20,
            callback: function (value, index, ticks) {
              return value + " %";
            },
          },
        },
        x: {
          grid: {
            display: false,
            tickLength: 0,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 90,
            minRotation: 90,
            padding: 20,
          },
          border: {
            display: false,
            color: "#D4D4D8",
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 5,
            padding: 25,
            boxHeight: 5,
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
      },
    },
  });
}

function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}
