const placeholders = [
  "Make today's 4th coffee",
  "Reorganize calculator collection",
  "Color-code sticky notes",
  "Alphabetize tax forms",
  "Polish desk nameplate",
  "Sort paperclips by size",
  "Realign desk items at perfect 90° angles",
  "Recalibrate office chair height",
  "Reconcile petty cash drawer",
  "Double-check decimal places in spreadsheet",
  "Sharpen all the pencils in the office",
  "Organize bookshelf by color",
  "Clean keyboard with cotton swab",
  "Check email again (still no new messages)",
  "Reorganize receipts by shade of white",
  "Create color-coded system for paperclips",
  "Perfectly align calculator with desk edge",
  "Count ceiling tiles for expense allocation",
  "Alphabetize sticky notes",
];

export default () => ({
  steps: [],

  init() {
    if (this.$el.dataset.initialSteps) {
      this.steps = JSON.parse(this.$el.dataset.initialSteps);
    }

    if (this.steps.length == 0) {
      this.addStep();
    }

    // remove empty steps before submitting
    let form = this.$el.closest("form");
    form.addEventListener("submit", () => {
      this.steps = this.steps.filter((s) => s.name.trim() != "");
    });
  },

  numberFor(step) {
    return this.steps.indexOf(step) + 1;
  },

  addStep() {
    this.steps.push({ id: Date.now(), name: "" });
  },

  removeStep(step) {
    this.steps = this.steps.filter((s) => s.id !== step.id);
  },

  domId(step) {
    return `step_${step.id}`;
  },

  randomStepPlaceholder() {
    return placeholders[Math.floor(Math.random() * placeholders.length)];
  },
});
