export default () => ({
  src: null,

  init() {
    let url = this.$el.dataset.currentUrl
    if (url) {
      this.src = url
    }
  },

  previewFile() {
    let file = this.$refs.fileInput.files[0];
    if (!file || file.type.indexOf('image/') === -1) return;
    
    let reader = new FileReader();
    reader.onload = e => {
      this.src = e.target.result;
    }
    reader.readAsDataURL(file);
  }
})