import accounting from "accounting";

let Percent = new Intl.NumberFormat("en-NZ", {
  style: "percent",
  maximumFractionDigits: 2,
  trailingZeroDisplay: "stripIfInteger",
});

export default () => ({
  categoryGLTotals: {},
  categoryRecTotals: {},
  parentIds: {},
  categoryChildIds: {},

  init() {
    const jsonParentIds = this.$el.dataset.parentIds;
    this.parentIds = jsonParentIds ? JSON.parse(jsonParentIds) : {};
    this.categoryChildIds = this.convertParentIdsToChildren(this.parentIds);

    const jsonCategoryNames = this.$el.dataset.categoryNames;
    this.categoryNames = jsonCategoryNames ? JSON.parse(jsonCategoryNames) : {};

    this.observer = new MutationObserver((records) => {
      const modifications = records.filter(({ target }) => {
        // ignore changes generated by Alpine
        return target.nodeType === Node.ELEMENT_NODE && !target.matches("*[x-text]");
      });

      // if something that's NOT the total lines was modified
      if (modifications.length > 0) this.calculateTotals();
    });

    this.observer.observe(this.$el, { attributes: false, childList: true, subtree: true });
    this.calculateTotals();
  },

  destroy() {
    if (this.observer) this.observer.remove();
  },

  calculateTotals() {
    this.categoryGLTotals = {};
    this.categoryRecTotals = {};

    this.$el.querySelectorAll("tr[data-category-id]").forEach((row) => {
      const categoryId = row.dataset.categoryId;

      if (!this.categoryGLTotals[categoryId]) this.categoryGLTotals[categoryId] = 0;
      const gl = row.querySelector(".gl-balance");
      if (gl) this.categoryGLTotals[categoryId] += accounting.unformat(gl.textContent);

      if (!this.categoryRecTotals[categoryId]) this.categoryRecTotals[categoryId] = 0;
      const rec = row.querySelector(".rec-balance");
      if (rec) this.categoryRecTotals[categoryId] += accounting.unformat(rec.textContent);
    });

    this.$el.querySelectorAll("tr[data-total-row]").forEach((totalRow) => {
      const categoryId = totalRow.dataset.totalRow;

      const children = this.categoryChildIds[categoryId] || [];
      this.categoryGLTotals[categoryId] = (this.categoryGLTotals[categoryId] || 0) + children.reduce((acc, childId) => acc + this.categoryGLTotals[childId] || 0, 0);
      this.categoryRecTotals[categoryId] = (this.categoryRecTotals[categoryId] || 0) + children.reduce((acc, childId) => acc + this.categoryRecTotals[childId] || 0, 0);
    });
  },

  varianceFor(categoryId) {
    if (this.categoryGLTotals[categoryId] == null || this.categoryRecTotals[categoryId] == null) return;

    return this.categoryRecTotals[categoryId] - this.categoryGLTotals[categoryId];
  },

  variancePercentFor(categoryId) {
    if (this.categoryGLTotals[categoryId] == null || this.categoryRecTotals[categoryId] == null || this.categoryGLTotals[categoryId] == 0) return;

    return this.varianceFor(categoryId) / Math.abs(this.categoryGLTotals[categoryId]);
  },

  formattedVariancePercentFor(categoryId) {
    if (this.variancePercentFor(categoryId) != null) {
      return Percent.format(this.variancePercentFor(categoryId));
    }
  },

  currencyFormat(n) {
    if (n == null) return;
    return accounting.formatMoney(n, {
      precision: 2,
      format: {
        pos: "%v",
        neg: "(%v)",
        zero: "%v",
      },
    });
  },

  convertParentIdsToChildren(parentIds) {
    let childrenForEachParent = {};

    Object.entries(parentIds).forEach(([childId, parentId]) => {
      if (!childrenForEachParent[parentId]) childrenForEachParent[parentId] = [];
      childrenForEachParent[parentId].push(childId);
    });

    return childrenForEachParent;
  },
});
