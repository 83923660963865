import tippy, { followCursor } from "tippy.js";

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;

  if (!chart.tippyTip) {
    chart.tippyTip = tippy(chart.canvas, {
      content: "Tooltip",
      trigger: "manual",
      animation: "fade",
      arrow: false,
      delay: [0, 0],
      interactive: false,
      followCursor: true,
      plugins: [followCursor],
      appendTo: document.body,
    });
  }

  const tippyTip = chart.tippyTip;

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tippyTip.hide();
    return;
  }

  tippyTip.show();

  // Set Text
  if (tooltip.body) {
    const tooltipData = tooltip.dataPoints[0];
    const statusType = tooltipData.dataset.labels[tooltipData.dataIndex];
    const s = tooltipData.raw > 1 ? "s" : "";
    const description = `${statusType}: ${tooltipData.formattedValue} task${s}`;

    tippyTip.setContent(description);
  }
};
