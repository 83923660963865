
// plus or minus
const workDayRange = 30;

export default () => ({

  workingDayLimit: 24,
  calendarDayLimit: 31,

  repeatOptions: [
    { 
      name: "Daily",
      value: "day",
    },
    { 
      name: "Weekly",
      value: "week",
    },
    { 
      name: "Two Weeks",
      value: "two_weeks",
    },
    { 
      name: "Month End",
      value: "month",
    },
    { 
      name: "Quarter End",
      value: "quarter",
    },
    { 
      name: "Half-Year End",
      value: "halfyear",
    },
    { 
      name: "Year End",
      value: "year",
    }
  ],

  currentOption: "month",

  includedWeekdays: [1,2,3,4,5],
  dueType: "working_day",
  selectedDay: -15,
  customSelectedBefore: "-25",
  customSelectedAfter: "25",

  init() {
  },

  get currentOptionDescription() {
    return this.repeatOptions.find(o => o.value == this.currentOption).name
  },

  get daysOfWeek() {
    return ["day", "week", "two_weeks"].includes(this.currentOption)
  },

  get previousDayOptions() {
    if (this.dueType == "working_day") {
      return Array.from({length: this.workingDayLimit}, (x, i) => i - this.workingDayLimit)
    } else {
      return [...this.nextDayOptions.map(n => n * -1)]
    }
  },

  get nextDayOptions() {
    if (this.dueType == "working_day") {
      return Array.from({length: this.workingDayLimit}, (x, i) => i + 1)
    } else {
      return Array.from({length: this.calendarDayLimit}, (x, i) => i + 1)
    }
  },

  get shouldIncludeCustomField() {
    return this.dueType == "working_day"
  },

  get shouldShowOrdinals() {
    return this.dueType == "calendar_day"
  },

  get dueCalendarType() {
    return this.dueDay < 0 ? "before" : "after"
  },

  get dueDay() {
    if (this.selectedDay == "before_custom") {
      return Math.abs(this.customSelectedBefore)
    } else if (this.selectedDay == "after_custom") {
      return this.customSelectedAfter
    } else {
      return this.selectedDay
    }
  },

  ordinalForDay(n) {
    let s = ["th", "st", "nd", "rd"],
        v = n % 100
    return (s[(v - 20) % 10] || s[v] || s[0])
  }

});