document.addEventListener("turbo:load", () => {
  listenForSubmits(document)
})

document.addEventListener("turbo:frame-load", (e) => {
  const frame = e.target
  listenForSubmits(frame)
})

function listenForSubmits(container) {
  container.querySelectorAll("form").forEach((form) => {

    form.addEventListener("turbo:submit-start", () => {
      form.querySelectorAll("button[type=submit]").forEach((button) => {
        button.classList.add("pointer-events-none", "opacity-50")
      })
    })

    form.addEventListener("turbo:submit-end", (e) => {
      if (e.detail.success && !form.dataset.skipReset) form.reset()
      form.querySelectorAll("button[type=submit]").forEach((button) => {
        button.classList.remove("pointer-events-none", "opacity-50")
      })
    })
  })
}