// yay stack overflow: https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript
const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const filterProperties = ["entityIds", "areaIds", "preparerIds", "reviewerIds", "overdue", "dueToday", "workDay", "preparationState", "reviewState", "periodTypes", "taskImportIds"];

export default () => ({
  entityIds: [],
  areaIds: [],
  preparerIds: [],
  reviewerIds: [],
  periodTypes: [],
  taskImportIds: [],

  overdue: false,
  dueToday: false,
  preparationState: "",
  reviewState: "",

  workDay: null,

  selectedFilter: null,
  savedFilters: [],

  init() {
    this.form = this.$el;

    if (this.form.dataset.initialFilter) {
      let initialFilter = JSON.parse(this.form.dataset.initialFilter);

      if (initialFilter) {
        this.entityIds = initialFilter.entity_ids || [];
        this.areaIds = initialFilter.area_ids || [];
        this.preparerIds = initialFilter.preparer_ids || [];
        this.reviewerIds = initialFilter.reviewer_ids || [];
        this.periodTypes = initialFilter.period_types || [];
        this.taskImportIds = initialFilter.task_import_ids || [];
        this.overdue = initialFilter.overdue || false;
        this.dueToday = initialFilter.due_today || false;
        this.preparationState = initialFilter.preparation_state || "";
        this.workDay = initialFilter.work_day || null;
        this.reviewState = initialFilter.review_state || "";
      }
    }

    const debouncedSubmit = Alpine.debounce(() => this.form.requestSubmit(), window.debounceDelay || 400);

    this.savedFilters = Array.from(this.$el.querySelectorAll(".saved-filter")).map((e) => JSON.parse(e.dataset.filter));
    this.selectedFilter = this.findSelectedFilter();
    this.updateQueryString();

    filterProperties.forEach((prop) => {
      this.$watch(prop, debouncedSubmit);
      this.$watch(prop, () => this.updateQueryString());

      // you changed shit, so let's see if there should be a selected filter
      this.$watch(prop, () => {
        this.selectedFilter = this.findSelectedFilter();
      });
    });
  },

  clearFilters() {
    this.entityIds = [];
    this.areaIds = [];
    this.preparerIds = [];
    this.reviewerIds = [];
    this.periodTypes = [];
    this.taskImportIds = [];
    this.overdue = false;
    this.dueToday = false;
    this.preparationState = "";
    this.workDay = null;
    this.reviewState = "";
    this.selectedFilter = null;
  },

  activateFilter(event) {
    const filter = JSON.parse(event.target.dataset.filter);

    filterProperties.forEach((prop) => {
      this[prop] = filter[camelToSnakeCase(prop)];
    });

    // need to do this in the next tick so it happens _after_ the watchers which reset
    // if you change OFF the filter :)
    this.$nextTick(() => (this.selectedFilter = filter));
  },

  get anyFiltersActive() {
    return [this.entityIds, this.areaIds, this.preparerIds, this.reviewerIds, this.periodTypes, this.taskImportIds].some((e) => e.length > 0) || this.statusFilterCount > 0;
  },

  get statusFilterCount() {
    return [this.overdue, this.dueToday, this.workDay != null && this.preparationState != undefined, this.preparationState != "" && this.preparationState != undefined, this.reviewState != "" && this.reviewState != undefined].filter((e) => e == true)
      .length;
  },

  toggleArrayElement(array, element) {
    if (array.includes(element)) {
      array.splice(array.indexOf(element), 1);
    } else {
      array.push(element);
    }
  },

  findSelectedFilter() {
    return (
      this.savedFilters.find((filter) => {
        if (filter.preparation_state == null) filter.preparation_state = "";
        if (filter.review_state == null) filter.review_state = "";

        return (
          filter.entity_ids.sort().join(",") == this.entityIds.sort().join(",") &&
          filter.area_ids.sort().join(",") == this.areaIds.sort().join(",") &&
          filter.preparer_ids.sort().join(",") == this.preparerIds.sort().join(",") &&
          filter.reviewer_ids.sort().join(",") == this.reviewerIds.sort().join(",") &&
          filter.task_import_ids.sort().join(",") == this.taskImportIds.sort().join(",") &&
          filter.period_types.sort().join(",") == this.periodTypes.sort().join(",") &&
          filter.overdue == this.overdue &&
          filter.due_today == this.dueToday &&
          filter.preparation_state == this.preparationState &&
          filter.work_day == this.workDay &&
          filter.review_state == this.reviewState
        );
      }) || null
    );
  },

  updateQueryString() {
    const filter = {
      entity_ids: this.entityIds,
      area_ids: this.areaIds,
      preparer_ids: this.preparerIds,
      reviewer_ids: this.reviewerIds,
      period_types: this.periodTypes,
      overdue: this.overdue,
      due_today: this.dueToday,
      preparation_state: this.preparationState,
      work_day: this.workDay,
      review_state: this.reviewState,
      task_import_ids: this.taskImportIds,
    };

    const stringFilter = Object.entries(filter)
      .map(([key, value]) => {
        if (value === false || value === null || value === undefined || value.length === 0) {
          return null;
        }

        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(`filter[${key}][]`)}=${encodeURIComponent(v)}`).join("&");
        } else {
          return `${encodeURIComponent(`filter[${key}]`)}=${encodeURIComponent(value)}`;
        }
      })
      .filter((n) => n)
      .join("&");

    const existingUrl = new URL(window.location.href);
    existingUrl.search = stringFilter;

    window.history.pushState({}, "", existingUrl);
  },

  serializeCurrentFilter(e) {
    let link = e.target.closest("a");

    const filter = {
      entity_ids: this.entityIds,
      area_ids: this.areaIds,
      preparer_ids: this.preparerIds,
      reviewer_ids: this.reviewerIds,
      period_types: this.periodTypes,
      overdue: this.overdue,
      due_today: this.dueToday,
      preparation_state: this.preparationState,
      work_day: this.workDay,
      review_state: this.reviewState,
      task_import_ids: this.taskImportIds,
    };

    const existingUrl = new URL(link.href);
    link.href = new URL(`${existingUrl.origin}${existingUrl.pathname}?filter=${JSON.stringify(filter)}`);
  },
});
