
export default () => ({

  commentCount: 0,


  init() {
    this.commentCount = this.$el.dataset.commentCount

    const observer = new MutationObserver(() => {
      this.commentCount = this.$refs.list.querySelectorAll("li").length
    });

    observer.observe(this.$refs.list, { attributes: false, childList: true, subtree: false });

  },

  destroy() {
    if (this.observer) this.observer.disconnect();
  }

});