export default () => ({

  scrolledPast: false,
  defaultMargin: 64,

  init() {
    const elementToWatch = document.querySelector(this.$el.dataset.elementToWatch);
    const margin = this.$el.dataset.scrollMargin || this.defaultMargin;
    this.observer = new IntersectionObserver(entries => {
      if (!entries[0].isIntersecting) {
        this.scrolledPast = true
      } else {
        this.scrolledPast = false
      }
    }, { rootMargin: `-${margin}px` });
    this.observer.observe(elementToWatch);
  },

  destroy() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
  }

})