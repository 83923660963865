import { format } from 'date-fns'
import { computePosition, shift, flip, autoUpdate } from '@floating-ui/dom'

export default () => ({
  
  MONTH_NAMES: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  DAYS: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

  showDatepicker: false,
  datepickerValue: '',
  selectedDate: null,
  earliestDate: null,
  
  disabled: false,
  month: '',
  year: '',
  no_of_days: [],
  blankdays: [],
  days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  
  init() {
    this.initDate()
    this.getNoOfDays()

    this.$watch('showDatepicker', (val) => {
      const menu = this.$refs.datepickerMenu
      const trigger = this.$refs.datepickerTrigger

      if (val) {
        this.cleanupPosition = autoUpdate(trigger, menu, () => this.positionMenu())
        this.positionMenu()
      } else {
        if (this.cleanupPosition) this.cleanupPosition()
      }
    })
  },

  initDate() {
    this.$watch('selectedDate', () => {

      const formatString = (this.selectedDate.getFullYear() === new Date().getFullYear()) ? "cccc, do MMM" : "cccc, do MMM yyyy"

      this.datepickerValue = format(this.selectedDate, formatString)

      if (this.$refs.date) {
        const existingValue = new Date(this.$refs.date.value)
        this.$refs.date.value = format(this.selectedDate, "yyyy-MM-dd");

        // if we changed, trigger it!
        if (this.selectedDate.toDateString() != existingValue.toDateString()) {
          const event = new Event('change');
          this.$refs.date.dispatchEvent(event);
        }
      }
    })

    if (this.$el.dataset.earliestDate) {
      this.earliestDate = new Date(JSON.parse(this.$el.dataset.earliestDate)).setHours(0,0,0,0)
    }

    this.selectedDate = new Date(JSON.parse(this.$el.dataset.initialDate))
    this.month = this.selectedDate.getMonth()
    this.year = this.selectedDate.getFullYear()
  },

  positionMenu() {
    const menu = this.$refs.datepickerMenu
    const trigger = this.$refs.datepickerTrigger

    computePosition(trigger, menu, {
      placement: 'bottom-end',
      middleware: [ shift(), flip() ]
    }).then(({x, y}) => {
      Object.assign(menu.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  },

  isSelected(date) {
    const d = new Date(this.year, this.month, date);

    return this.selectedDate.toDateString() === d.toDateString()
  },
  
  isToday(date) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    
    return today.toDateString() === d.toDateString()
  },

  isEnabled(date) {
    if (!this.earliestDate) return true

    const d = new Date(this.year, this.month, date);
    if (d >= this.earliestDate) return true

    return false
  },
  
  chooseDate(date) {
    this.selectedDate = new Date(this.year, this.month, date);
    this.showDatepicker = false;
  },
  
  getNoOfDays() {
    let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
    
    // find where to start calendar day of week
    let dayOfWeek = new Date(this.year, this.month).getDay();
    let blankdaysArray = [];
    for ( var i=1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }
    
    let daysArray = [];
    for ( var i=1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    
    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }
  
});