import "@hotwired/turbo-rails";

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "trix";
import "@rails/actiontext";

import LocalTime from "local-time";

import "./channels";

import "./utils/disable-submit";
import "./utils/sticky-stuck";
import "./utils/charts";
import "./utils/save-user-preference";
import "./utils/read-only-forms";

import "alpine-turbo-drive-adapter";
import ui from "@alpinejs/ui";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import mask from "@alpinejs/mask";

// hax version of the alpinejs components
import searchMenu from "./components/search-menu";

import Tooltip from "@ryangjchandler/alpine-tooltip";

import daySelector from "./components/day-selector";
import inlineAddSelect from "./components/inline-add-select";
import emptyState from "./components/empty-state";
import imagePreview from "./components/image-preview";
import datepicker from "./components/datepicker";
import attachmentsUploader from "./components/attachments-uploader";
import commentCounter from "./components/comment-counter";
import commentMentions from "./components/comment-mentions";
import highlightMentions from "./components/highlight-mentions";
import sortableTable from "./components/sortable-table";
import filterForm from "./components/filter-form";
import inbox from "./components/inbox";
import notification from "./components/notification";
import emojiPicker from "./components/emoji-picker";
import sharedListbox from "./components/shared-listbox";
import userSelectTrigger from "./components/user-select-trigger";
import holidaySelector from "./components/holiday-selector";
import scrollListener from "./components/scroll-listener";
import repeatField from "./components/repeat-field";
import varianceDisplay from "./components/variance-display";
import automaticReconciliationOptions from "./components/automatic-reconciliation-options";
import tableWithTotals from "./components/table-with-totals";
import collapsibleTable from "./components/collapsible-table";
import loginForm from "./components/login-form";
import scratchPadOrdering from "./components/scratch-pad-ordering";
import onThisDay from "./components/on-this-day";
import greeting from "./components/greeting";
import taskStepEditor from "./components/task-step-editor";

import Alpine from "alpinejs";

Alpine.data("daySelector", daySelector);
Alpine.data("inlineAddSelect", inlineAddSelect);
Alpine.data("emptyState", emptyState);
Alpine.data("imagePreview", imagePreview);
Alpine.data("datepicker", datepicker);
Alpine.data("attachmentsUploader", attachmentsUploader);
Alpine.data("commentCounter", commentCounter);
Alpine.data("commentMentions", commentMentions);
Alpine.data("highlightMentions", highlightMentions);
Alpine.data("sortableTable", sortableTable);
Alpine.data("filterForm", filterForm);
Alpine.data("inbox", inbox);
Alpine.data("notification", notification);
Alpine.data("emojiPicker", emojiPicker);
Alpine.data("sharedListbox", sharedListbox);
Alpine.data("userSelectTrigger", userSelectTrigger);
Alpine.data("holidaySelector", holidaySelector);
Alpine.data("scrollListener", scrollListener);
Alpine.data("repeatField", repeatField);
Alpine.data("varianceDisplay", varianceDisplay);
Alpine.data("automaticReconciliationOptions", automaticReconciliationOptions);
Alpine.data("tableWithTotals", tableWithTotals);
Alpine.data("collapsibleTable", collapsibleTable);
Alpine.data("loginForm", loginForm);
Alpine.data("scratchPadOrdering", scratchPadOrdering);
Alpine.data("onThisDay", onThisDay);
Alpine.data("greeting", greeting);
Alpine.data("taskStepEditor", taskStepEditor);
Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(searchMenu);
Alpine.plugin(Tooltip);
Alpine.plugin(collapse);
Alpine.plugin(mask);

document.addEventListener("alpine:initialized", () => {
  document.body.classList.add("alpine-initialized");
});

window.Alpine = Alpine;
Alpine.start();

LocalTime.start();
