window.saveUserPreference = (prefName, value) => {

  const formData = new FormData()
  formData.append(`user[${prefName}]`, value)

  if (document.querySelector("meta[name='csrf-param']")) {
    formData.append(document.querySelector("meta[name='csrf-param']").content, document.querySelector("meta[name='csrf-token']").content)
  }

  const updatePreferenceUrl = document.querySelector("meta[name='update-preference-url']").content

  fetch(updatePreferenceUrl, {
    body: formData,
    method: "PATCH"
  }) 
}