export default () => ({
  collapsedCategories: [],
  parentIds: {},

  init() {
    const jsonParentIds = this.$el.dataset.parentIds;
    this.parentIds = jsonParentIds ? JSON.parse(jsonParentIds) : {};
  },

  isCollapsed(categoryId) {
    if (categoryId == null) return false;

    return this.collapsedCategories.includes(categoryId) || this.isCollapsed(this.parentIds[categoryId]);
  },

  toggleCollapsed(categoryId) {
    if (this.isCollapsed(categoryId)) {
      this.collapsedCategories = this.collapsedCategories.filter((id) => id !== categoryId);
    } else {
      this.collapsedCategories.push(categoryId);
    }
  },
});
