import { computePosition } from "@floating-ui/dom";

export default () => ({
  users: [],
  onlyUsers: null,
  currentUserId: null,
  listboxComponent: null,
  listboxEl: null,
  value: null,
  valueTarget: null,

  init() {
    this.users = window.userData || JSON.parse(document.querySelector("#user_selector_data").innerText);
    this.currentUserId = this.$el.dataset.currentUserId;

    this.listboxEl = this.$el.querySelector("[x-listbox]");

    this.$nextTick(() => {
      if (!this.listboxComponent || this.listboxComponent == null) return;

      this.$watch("listboxComponent.__isOpen", (open) => {
        if (!open) this.onClose();
      });

      this.$watch("listboxComponent.value", (val) => this.valueSelected(val));
    });
  },

  valueSelected(val) {
    // only wanna fire the change if the listbox has just been closed
    // not when it first opens :)
    if (this.valueTarget && !this.listboxComponent.__isOpen) {
      this.valueTarget.value = val ? val.id : "";
      this.$dispatch("shared-listbox-value-selected", { val, targetId: this.valueTarget.id });
    }
  },

  onClose() {
    document.body.classList.remove("stop-scrolling");
    document.body.dataset.sharedSelectActive = "";
  },

  open(event) {
    const { triggeringEl, valueTarget, onlyUsers } = event.detail;

    document.body.classList.add("stop-scrolling");
    document.body.dataset.sharedSelectActive = true;

    this.valueTarget = valueTarget;
    if (valueTarget) {
      this.value = this.users.find((u) => u.id === valueTarget.value);
    }

    this.onlyUsers = onlyUsers ? onlyUsers.split(",") : null;

    computePosition(triggeringEl, this.listboxEl, {
      placement: "bottom-start",
    }).then(({ x, y }) => {
      Object.assign(this.listboxEl.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });

    this.listboxEl.classList.remove("hidden");

    if (this.listboxComponent) this.listboxComponent.__open();

    event.preventDefault();
    return false;
  },
});
